











import Vue from "vue";
export default Vue.extend({
  props: {
    value: String,
    quality: Number,
    scale: Number,
    width: Number,
    margin: Number,
    size: Number,
    whiteMargin: Boolean,
  },
  data() {
    return {
      imageUrl: require(`@/assets/image/logo_gpw.png`),
    };
  },
  components: {
    VueQr: () => import(/* webpackPrefetch: true */ "vue-qr"),
  },
  methods: {
    onCallback(dataUrl): void {
      this.$emit("onCallback", dataUrl)
    }
  }
});
